<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.viewScheduler')"
    id="viewScheduler"
  >
    <div id="outer-title">{{ $t("i18n.viewScheduler") }}</div>
    <full-calendar :options="calendarOptions" />
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, ref } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
export default {
  name: "UpkeepContractViewschedule",
  components: {
    FullCalendar,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const cal = ref(null);
    const state = reactive({
      calendarOptions: {
        buttonText: { today: "今天", month: "月", week: "周", day: "日" },
        locale: "zh-cn",
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        dayMaxEventRows: true,
        events: (date, callback) => {
          getScheduleData(date, callback);
        },
      },
    });

    const getScheduleData = async (date, callback) => {
      let start = date.start.getTime();
      let end = date.end.getTime();
      let { data } = await proxy.$api.maintenance.getScheduleData(start, end);
      var eventsArray = [];
      data.map((item) => {
        var event = {};
        event.title = item.title;
        event.start = item.time;
        if (item.type == 20) {
          event.color = "#E0D23A";
        } else if (item.type == 30) {
          event.color = "#9959F2";
        } else if (item.type == 40) {
          event.color = "#AAC65F";
        } else {
          event.color = "#3694F4";
        }
        eventsArray.push(event);
      });
      callback(eventsArray);
    };

    return {
      ...toRefs(state),
      cal,
      getScheduleData,
    };
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
  #viewScheduler {
    .fc-daygrid-day-bottom {
      margin-top: -5px !important;
      padding: 0;
      .fc-daygrid-more-link {
        width: 100%;
        display: block;
        color: orange;
      }
    }
  }
}
</style>
